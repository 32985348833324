@media (max-width: 480px) {
  .info-card {
    width: calc(100vw - 20px);
    margin: 10px 10px;
  }
}
@media (min-width: 481px) {
  .info-card {
    width: 360px;
    margin: 10px 10px;
  }
  .info-card.focused {
    animation-name: hightlight;
    animation-duration:2s;
  }

  @keyframes hightlight {
    from {
      background-color: rgba(241, 245, 22, 0.699);
    }
    to {
      background-color: white;
    }
  }
}


.info-card h3 {
  margin-bottom: 0px;
}

.maskNum {
  > span {
    margin-right: 24px;
  }
}
.note {
  display: flex;
  font-size: .875rem;
  color: var(--mdc-theme-text-secondary-on-background,rgba(0,0,0,.54));
  height: 40px;
  margin-bottom: 14px;
  white-space: normal;
}

.card__primary {
  padding: 0rem 1rem;
  h2 {
    margin-bottom: 0px;
  }
  h3 {
    margin-top: 0px;
    color: var(--mdc-theme-text-secondary-on-background,rgba(0,0,0,.54));
    font-size: .875rem;
  }
}

.card__secondary {
  padding: 1rem;
}

.mdc-card__ripple{
  display: none !important;
}